<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-01-11 14:12:21
-->
<template>
	<div class="contain">

		<!-- 用户头 -->
		<personInfo></personInfo>
		<!-- 侧边栏 -->
		<Sidebar :showCar="true" @showCar="showCar" @showShare="showShare" :shareShow="shareShow" ref="sideBar">
		</Sidebar>
		<!-- 商品专区 -->
		<div class="goodArea">
			<div class="goodArea_l">
				<div :class="['goodArea_i',{'actArea':specialArea0 == item.value}]" v-for="item in zoneList"
					:key="item.value" v-show="item.isShow" @click="changeArea(item)">{{ item.label }}</div>
			</div>
			<div class="goodArea_r">
				<div v-show="specialArea == 1" class="classIfy actOne">{{ $t('w_0037') }}</div>
				<el-dropdown trigger="click" v-show="specialArea == 1" placement="bottom-start">
					<div class="kuang">
						<div class="dropdown_i">
							<img :src="ruleForm.nationalFlag2" alt="">
							<div>{{ ruleForm.name }}</div>
						</div>
						<i slot="suffix" class="el-icon-arrow-down"></i>
					</div>
					<el-dropdown-menu slot="dropdown" class="dropdown-container">
						<el-dropdown-item v-for="(item, i) in countryList" :key="i">
							<div class="dropdown_i" @click="handleClick(item)">
								<img :src="item.nationalFlag2" alt="">
								<div>{{ item.name }}</div>
							</div>

						</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
				<div class="myCar"
					v-if='userInfo.registerShare != 0 && userInfo.registerFans != 0&&pkCountry == userInfo.pkCountry'
					@click="openCar">
					<i class="el-icon-shopping-cart-full"></i>
					<div>{{ $t('w_0038') }}({{ cont }})</div>
					<!-- <div class="quan"></div> -->
				</div>
			</div>
		</div>
		<!-- 二级专区 -->
		<div class="areaType">
			<div v-for="item in areaTwoList" :class="['classIfy',specialArea == item.value?'actOne':'']"
				v-show="item.isShow" @click="changeArea2(item)" :key="item.value">{{ item.label }}</div>
		</div>
		<div v-if="!isPost">
			<!-- 海粉渠道 -->
			<!-- <div class="oneType1"
                       v-if="this.specialArea == 21">
                    <div>渠道:</div>
                    <div v-for="item in sourceList"
                         @click="getOclass(item.source
            )"
                         :class="['classIfy',source == item.source
            ?'actOne':'']"
                         :key="item.pkId">{{ item.name }}</div>
                  </div> -->
			<!-- 一级分类 -->
			<div class="oneType" v-if="specialArea!=21">
				<div class="clify">{{ $t('MN_F_T_61') }}:</div>
				<div v-for="item in oneList" :class="['classIfy',oneId == item.pkId?'actOne':'']"
					@click="oneId=item.pkId,getAllGoods(item.pkId),getClassIfyTwo(specialArea,item.pkId)"
					:key="item.pkId">{{ item.classifyName }}</div>
			</div>
			<!-- 二级分类 -->
			<div class="twoType_a" v-if="specialArea!=21">
				<div class="twoType">
					<!-- <div>二级分类:</div> -->
					<div v-for="item in twoList" :class="['classIfy',twoId == item.pkId?'actOne':'']"
						@click="twoId=item.pkId,getAllGoods1(item.pkId)" :key="item.pkId">{{ item.classifyName }}</div>
				</div>
			</div>
			<!-- 一级分类 海粉 -->
			<div class="oneType" v-if="specialArea==21">
				<div class="clify">{{ $t('MN_F_T_61') }}:</div>
				<div class="oneType2">
					<div v-for="item in oneList" :class="['classIfy',oneId == item.pkId?'actOne':'']"
						@click="getOne(item)" :key="item.pkId">{{ item.title }}</div>
				</div>
			</div>
			<!-- 二级分类 海粉 -->
			<div class="twoType_a" v-if="specialArea==21">
				<!-- <div class="clify">{{ $t('MN_F_T_61') }}:</div> -->
				<div class="twoType">
					<div v-for="item in twoList" :class="['classIfy',twoId == item.pkId?'actOne':'']"
						@click="getTwo(item)" :key="item.pkId">{{ item.title }}</div>
				</div>
				<!-- 三级分类 海粉-->
				<div class="twoType">
					<div v-for="item in threeList" :class="['classIfy',threeId == item.pkId?'actOne':'']"
						@click="getThree(item)" :key="item.pkId">{{ item.title }}</div>
				</div>
			</div>
			<!-- 三级分类 海粉-->
			<!-- <div class="twoType_a" v-if="specialArea==21">
                    
                </div> -->
			<!-- 商品查询 -->
			<div v-if="specialArea==21" class="search">
				<el-row :gutter="10">
					<el-col :span="8">
						<el-input :placeholder="$t('w_0040')" @keyup.enter.native="getAllGoods1()" v-model="waresName"
							clearable>
						</el-input>
					</el-col>
					<el-col :span="8" class="coll">
						<el-input @keyup.enter.native="getAllGoods1()" v-model="startPrice"
							oninput="value=value.replace(/[^\d]/g,'')" clearable>
							<div slot="prefix" class="el-input__icon">￥</div>
						</el-input>
						<div>-</div>
						<el-input @keyup.enter.native="getAllGoods1()" v-model="endPrice"
							oninput="value=value.replace(/[^\d]/g,'')" clearable>
							<div slot="prefix" class="el-input__icon">￥</div>
						</el-input>
					</el-col>
					<el-col :span="4">
						<el-button type="danger" class="delBtn" @click="getAllGoods1()">{{ $t('N_I_183') }}</el-button>
					</el-col>
				</el-row>

			</div>
			<div v-if="specialArea!=21" class="search">
				<el-row :gutter="10" style="width:100%">
					<el-col :span="8">
						<el-input :placeholder="$t('w_0040')" @keyup.enter.native="getAllGoods1()" v-model="waresName"
							clearable>
						</el-input>
					</el-col>
					<el-col :span="4">
						<el-button type="danger" class="delBtn" @click="getAllGoods1()">{{ $t('N_I_183') }}</el-button>
					</el-col>
				</el-row>

			</div>
			<!-- 商品列表 -->
			<div class="goodList">
				<div class="expectation-box d-c d-c-c" v-if="goodList == '' && !loading">
					<img class="expectation-img" src="@/assets/images/expectation.png" alt="" />
					<div class="expectation-text">敬请期待</div>
				</div>
				<div class="good_i" v-for="item in goodList" :key="item.waresCode">

					<!-- 缺货无货售罄 -->
					<div v-show="item.preSaleStatus==3||item.isSale==1">
						<div class="cool" v-if="item.coolLabelTarget != undefined">
							<img :src="item.coolLabelTarget.labelImage" alt="">
						</div>
						<div class="motai">

						</div>

						<img class="goodImg" :src="item.cover1" alt="">
						<div class="goodBtm">
							<div class="disFlex">
								<div class="tit1 hui">{{ userInfo.currencyIcon }}{{ item.waresPrice |numberToCurrency }}
								</div>
							</div>
							<div class="disFlex">
								<div class="tit3">
									<span class="qzbq hui_b"
										v-if="item.prefixLabelTarget != undefined">{{ item.prefixLabelTarget.label }}</span>
									<span>{{ item.waresName }}</span>
								</div>
							</div>
							<div class="disFlex">
								<div class="tit4 hui">
									{{$t('CK_KS_102')}}:{{ item.sales |seles}}
								</div>
								<div class="tit4 hui" v-show="specialArea != 13" style="margin-left:10px">
									{{ $t('ENU_TOTAL_V_1') }}:{{ item.waresAchieve |numberToCurrency}}
								</div>
								<div class="tit4" v-show="specialArea == 13" style="margin-left:10px">
									BV:{{ item.assAchieve |numberToCurrency}}
								</div>
							</div>
							<!-- <img class="addCarImg" :src="addCarImg3" alt=""> -->
							<div class="addCarImg1">
								<img :src="addCarImg2" alt="">
								<div>{{ $t('N_I_192') }}</div>
							</div>
							<div class="md">
								<img v-for="ctem in item.sellingLabelList" v-show="item.sellingLabelList"
									:key="ctem.pkId" :src="ctem.labelImage||''" alt="">
							</div>
						</div>
					</div>
					<!-- 正常商品 -->
					<div v-show="item.preSaleStatus!=3&&item.isSale!=1" @click="goDetails(item)">
						<div class="cool" v-if="item.coolLabelTarget != undefined">
							<img :src="item.coolLabelTarget.labelImage" alt="">
						</div>
						<img class="goodImg" :src="item.cover1" alt="">
						<div class="goodBtm">
							<div class="disFlex" v-show="specialArea != 31">
								<div class="tit1">{{ userInfo.currencyIcon }}{{ item.waresPrice | numberToCurrency }}
								</div>
							</div>
							<div v-show="specialArea == 31">
								<div class="disFlex1" v-if="userInfo.isMakerSpace == 0">
									<div class="tit1">
										{{ userInfo.currencyIcon }}{{ item.waresPrice | numberToCurrency }}
									</div>
									<!-- <div class="fflex">
                  <div class="ling">统一零售价:</div>
                <div class="ling">{{ userInfo.currencyIcon }}{{ item.retailPrice |numberToCurrency}}</div>
              </div> -->
								</div>
								<div class="disFlex1" v-if="userInfo.isMakerSpace == 1">
									<div class="tit1">{{ userInfo.currencyIcon }}{{ item.vipPrice | numberToCurrency }}
									</div>
									<!-- <div class="fflex">
                  <div class="ling">统一零售价:</div>
                <div class="ling">{{ userInfo.currencyIcon }}{{ item.retailPrice |numberToCurrency}}</div>
              </div> -->
								</div>
							</div>
							<div class="disFlex">
								<div class="tit3">
									<span class="qzbq"
										v-if="item.prefixLabelTarget != undefined">{{ item.prefixLabelTarget.label }}</span>
									<span>{{ item.waresName }}</span>
								</div>
							</div>
							<div class="disFlex">
								<div class="tit4">
									{{$t('CK_KS_102')}}:{{ item.sales |seles}}
								</div>
								<div class="tit4" v-show="specialArea != 13&&specialArea != 31"
									style="margin-left:10px">
									{{ $t('ENU_TOTAL_V_1') }}:{{ item.waresAchieve | numberToCurrency}}
								</div>
								<div class="tit4" v-show="specialArea == 31&&userInfo.isMakerSpace == 0"
									style="margin-left:10px">
									{{ $t('ENU_TOTAL_V_1') }}:{{ item.waresAchieve | numberToCurrency}}
								</div>
								<div class="tit4" v-show="specialArea == 13" style="margin-left:10px">
									BV:{{ item.assAchieve |numberToCurrency}}
								</div>
							</div>
							<div class="tit4" v-show="specialArea == 10">
								{{ $t('w_0118') }}:{{ item.deductMoney | numberToCurrency}}
							</div>
							<!-- <img class="addCarImg" v-show="isClick != item.waresCode" :src="addCarImg1" @click.stop='addToCar($event,item)' alt="">
                            <img class="addCarImg" v-show="isClick== item.waresCode" :src="addCarImg2" alt=""> -->
							<div class="addCarImg"
								v-show="(specialArea == 31&&userInfo.isMakerSpace == 0)||specialArea !=31"
								@click.stop='addToCar($event,item)'>
								<img :src="addCarImg1" alt="">
								<div>{{ $t('N_I_192') }}</div>
							</div>
							<div class="md">
								<img v-for="ctem in item.sellingLabelList" v-show="item.sellingLabelList"
									:key="ctem.pkId" :src="ctem.labelImage||''" alt="">
							</div>
						</div>
					</div>
				</div>
			</div>
			<pagination v-show="total>0&&specialArea == 21" :total="total" :page.sync="pageNum" :limit.sync="pageSize"
				@pagination="getAllGoods1()" />
		</div>
		<poster v-if="isPost"></poster>
		<vue-ball ref="ball" :duration="300" @after-enter="afterEnter">
			<div class="ccc" :style="{top:carTop + 'px'}"></div>
			<div slot="icon" class="dian"></div>
		</vue-ball>
		<div>
			<!-- 公告弹窗 -->
			<notice-popup :userInfo="userInfo" :publishLocationIndex="index"></notice-popup>
		</div>
		<selSpaceGoods ref="selSpaceGoods" @getCar="getCatLength"></selSpaceGoods>
	</div>
</template>

<script>
	import personInfo from '@/components/personInfo.vue'
	import Sidebar from '@/components/Sidebar.vue'
	import poster from '@/components/poster.vue'
	import * as api from '@/api/goods.js'
	import {
		Ball as vueBall
	} from '@hyhello/vue-ball'
	import {
		mapGetters
	} from 'vuex'
	import noticePopup from '@/components/noticePopup.vue'
	import selSpaceGoods from '@/components/selSpaceGoods.vue'
	import * as ads from '@/api/register.js'

	export default {
		components: {
			noticePopup,
			personInfo,
			Sidebar,
			vueBall,
			poster,
			selSpaceGoods,
		},
		computed: {
			...mapGetters(['userInfo', 'cont']),
		},
		filters: {
			seles(value) {
				if (value > 999) {
					return 999 + '+'
				} else {
					return value
				}
			},
		},
		data() {
			return {
				loading: true,
				isPost: false,
				waresName: '',
				startPrice: '',
				endPrice: '',
				index: 30,
				zoneList: [{
						label: this.$t('N_I_1'),
						value: 100,
						name: 'share',
						children: [{
								label: this.$t('N_I_2'),
								value: 7,
								name: 'hiFans',
							},
							{
								label: this.$t('N_I_3'),
								value: 21,
								name: 'haiFans',
							},
						],
					},
					{
						label: this.$t('ENU_SPECIAL_A_1'),
						value: 1,
						name: 'regiest',
						children: [{
								label: this.$t('ENU_MENU_321'),
								value: 1,
								name: 'regiestArea',
							},
							{
								label: this.$t('ENU_MENU_335'),
								value: 24,
								name: 'ezLearnReg',
							},
						],
					},
					{
						label: this.$t('ENU_MENU_320'),
						value: 2,
						name: 'upgrade',
						children: [{
								label: this.$t('ENU_MENU_321'),
								value: 2,
								name: 'upgradeArea',
							},
							{
								label: this.$t('ENU_MENU_335'),
								value: 25,
								name: 'ezLearnUp',
							},
							{
								label: this.$t('ENU_SPECIAL_A_7'),
								value: 27,
								name: 'haiFunUpd',
							},
						],
					},
					{
						label: this.$t('ENU_MENU_330'),
						value: 3,
						name: 'repurchase',
						children: [{
								label: this.$t('ENU_MENU_321'),
								value: 3,
								name: 'self',
							},
							{
								label: this.$t('N_I_4'),
								value: 21,
								name: 'haiFans',
							},
							{
								label: this.$t('ENU_SPECIAL_A_22'),
								value: 22,
								name: 'cooperation',
							},
							{
								label: this.$t('ENU_MENU_335'),
								value: 26,
								name: 'ezLearnRep',
							},
							{
								label: this.$t('ENU_MENU_334'),
								value: 14,
								name: 'live',
							},
							{
								label: this.$t('ENU_ORDER_T_12'),
								value: 12,
								name: 'gongju',
							},
						],
					},
					{
						label: this.$t('ENU_MENU_340'),
						value: 13,
						children: [],
						name: 'welfare',
					},
					{
						label: '积分专区',
						value: 11,
						children: [],
						name: 'integral',
					},
					{
						label: this.$t('ENU_MENU_360'),
						value: 10,
						children: [],
						name: 'rescission',
					},
					{
						label: '续约专区',
						value: 30,
						children: [],
						name: "renewalArea",
					},
					{
						label: '专供专区',
						value: 31,
						children: [],
						name: "wolesaleArea",
					},
					{
						label: this.$t('w_1014'),
						value: 28,
						children: [],
						name: "makerArea",
					},
				],
				specialArea: 0,
				specialArea0: 0,
				goodList: [],
				addCarImg1: require('@/assets/images/cart.png'),
				addCarImg2: require('@/assets/images/cart1.png'),
				// addCarImg2: require('@/assets/images/addCar2.png'),
				addCarImg3: require('@/assets/images/addCar3.png'),
				isClick: false,
				heartBeat: false,
				carTop: 0,
				oneList: [],
				twoList: [],
				threeList: [],
				areaTwoList: [],
				oneId: '',
				twoId: '',
				threeId: '',
				carLength: 0,
				sourceList: [],
				source: 1,
				shareShow: true,
				toShowCar: false,
				countryList: [],
				ruleForm: {},
				pkCountry: '',

				pageNum: 1,
				pageSize: 50,
				total: 100,
				moneySymbol: '',
			}
		},
		watch: {
			$route(n, o) {
				if (n.query.idTh != undefined) {
					this.specialArea0 = n.query.id
					this.specialArea = n.query.idTh
					// ||
					this.zoneList.forEach((item) => {
						if (item.value == this.specialArea0) {
							this.areaTwoList = item.children

						}
					})
				} else {
					this.specialArea0 = n.query.id
					this.specialArea = n.query.id
					this.areaTwoList = []
				}
				if (this.specialArea0 == 100 && this.specialArea == 21) {
					this.isPost = true
				} else {
					this.isPost = false
				}
				if (this.userInfo.registerShare == 0 || this.userInfo.registerFans == 0) {
					this.shareShow = false
				} else {
					this.shareShow = true
				}
				this.pageNum = 1
				// this.getAllGoods()
				// 获取分类一级
				this.getClassIfy()

				this.carTop = this.$children[1].$refs.shoppCar.getBoundingClientRect().top
			},
			specialArea(n) {
				// if (n == 7 || n == 21) {
				//   this.shareShow = true
				// } else {
				//   this.shareShow = false
				// }
				if (n == 1) {
					if (localStorage.getItem('pkCountry')) {
						this.pkCountry = localStorage.getItem('pkCountry')
					} else {
						this.pkCountry = this.userInfo.pkSettleCountry
					}
					// this.moneySymbol = this.userInfo.currencyIcon
					// this.getMoneySymbol()
				} else {
					this.pkCountry = this.userInfo.pkSettleCountry

					// this.moneySymbol = this.userInfo.currencyIcon
				}
				this.getCatLength()
			},
		},
		created() {
			// this.getSpecialArea()
			// this.pkCountry = this.userInfo.pkSettleCountry
			// localStorage.setItem('pkCountry', this.pkCountry)
		},
		mounted() {
			if (this.$route.query.idTh != undefined) {
				this.specialArea0 = this.$route.query.id
				this.specialArea = this.$route.query.idTh
				this.zoneList.forEach((item) => {
					if (item.value == this.specialArea0) {
						this.areaTwoList = item.children
					}

				})
			} else {
				this.specialArea0 = this.$route.query.id
				this.specialArea = this.$route.query.id
				this.areaTwoList = []
			}
			if (this.specialArea0 == 100 && this.specialArea == 21) {
				this.isPost = true
			} else {
				this.isPost = false
			}
			if (this.specialArea == 31) {
				this.$refs.sideBar.getSpecial(31)
			}
			if (this.userInfo.registerShare == 0 || this.userInfo.registerFans == 0) {
				this.shareShow = false
			} else {
				this.shareShow = true
			}

			this.getMenuList()
			// this.specialArea = this.$route.query.id
			// this.$refs.sideBar.getCarList(this.specialArea)
			// this.getAllGoods()
			// 获取分类一级
			if (this.isPost) {

			} else {
				this.getClassIfy()
			}

			// 获取海粉渠道
			// this.getSource()

			this.$nextTick(() => {
				this.carTop = this.$children[1].$refs.shoppCar.getBoundingClientRect().top
			})

			// this.zoneList = JSON.parse(localStorage.getItem('productMenu'))
			if (this.specialArea == 1) {
				if (localStorage.getItem('pkCountry')) {
					this.pkCountry = localStorage.getItem('pkCountry')
				} else {
					this.pkCountry = this.userInfo.pkSettleCountry
					localStorage.setItem('pkCountry', this.pkCountry)
				}
			} else {
				this.pkCountry = this.userInfo.pkSettleCountry
				localStorage.setItem('pkCountry', this.pkCountry)
			}

			this.getCatLength()
			// 获取国家
			this.getJScountry()
		},
		methods: {
			getAuth() {
				ads.agreementName().then((res) => {
					if (res.data == 0) {
						this.$router.push({
							path: '/shoppingMall',
							query: {
								id: 30
							},
						})
						this.$refs.sideBar.getSpecial(item.value)
					} else {
						this.$confirm(`${this.$t('MY_WAL_50')}?`, this.$t('w_0034'), {
								confirmButtonText: this.$t('w_0035'),
								cancelButtonText: this.$t('ENU_P_TYPE0'),
								type: 'warning',
							})
							.then(() => {
								this.$router.push({
									path: '/vipCenter',
									query: {
										id: 5,
										atabActiveId: 9
									},
								})
							})
							.catch(() => {})
					}
				})
			},
			getMoneySymbol() {
				api
					.getCurrency({
						pkCountry: this.pkCountry,
					})
					.then((res) => {
						// this.moneySymbol = res.data.symbol
					})
			},
			getOne(item) {
				this.pageNum = 1
				this.oneId = item.pkId
				this.twoId = ''
				this.getAllGoods(item.pkId)
				this.getClassIfyTwo(this.specialArea, item.pkId)
			},
			getTwo(item) {
				this.pageNum = 1
				this.twoId = item.pkId
				this.threeId = ''
				let arr = []
				if (this.twoId == '') {
					let twoIds = this.twoList.map((item) => {
						return item.pkId
					})
					this.trimSpace(twoIds).then((res) => {
						arr = res
						this.getAllGoods1()
						this.getClassIfyThree(arr)
					})
				} else {
					arr = [this.twoId]
					this.getAllGoods1()
					this.getClassIfyThree(arr)
				}
			},
			getThree(item) {
				this.pageNum = 1
				this.threeId = item.pkId
				this.getAllGoods1()
			},
			handleClick(item) {
				this.ruleForm.nationalFlag2 = item.nationalFlag2
				this.ruleForm.name = item.name
				this.pkCountry = item.pkId
				// this.getMoneySymbol()
				localStorage.setItem('pkCountry', item.pkId)
				// 获取分类一级
				this.getClassIfy()
				this.getCatLength()
			},
			getJScountry() {
				api.currencyList().then((res) => {
					this.countryList = res.data
					this.countryList.forEach((item) => {
						if (item.pkId == localStorage.getItem('pkCountry')) {
							this.ruleForm.nationalFlag2 = item.nationalFlag2
							this.ruleForm.name = item.shortName
							// localStorage.setItem('pkCountry', item.pkId)
						}
					})
				})
			},
			// getSource() {
			//     api.jxhhSource(1).then((res) => {
			//         this.sourceList = res.data
			//     })
			// },
			getMenuList() {
				// if (this.userInfo.registerShare == 0) {
				//   this.zoneList = [
				//     {
				//       label: this.$t('N_I_1'),
				//       value: 100,
				//       name: 'share',
				//       isShow: true,
				//       children: [
				//         {
				//           label: this.$t('N_I_3'),
				//           value: 21,
				//           name: 'haiFans',
				//           isShow: true,
				//         },
				//       ],
				//     },
				//     {
				//       label: this.$t('ENU_MENU_320'),
				//       value: 2,
				//       name: 'upgrade',
				//       isShow: true,
				//       children: [
				//         {
				//           label: this.$t('ENU_MENU_321'),
				//           value: 2,
				//           name: 'upgradeArea',
				//           isShow: true,
				//         },
				//         {
				//         label: this.$t('ENU_SPECIAL_A_7'),
				//         value: 27,
				//         name: "haiFunUpd",
				//         isShow: true,
				//       },
				//       ],
				//     },

				//     {
				//       label: this.$t('ENU_MENU_330'),
				//       value: 3,
				//       name: 'repurchase',
				//       isShow: true,
				//       children: [
				//         {
				//           label: this.$t('ENU_MENU_321'),
				//           value: 3,
				//           name: 'self',
				//           isShow: true,
				//         },
				//         {
				//           label: this.$t('N_I_4'),
				//           value: 21,
				//           name: 'haiFans',
				//           isShow: true,
				//         },
				//         {
				//           label: this.$t('ENU_MENU_332'),
				//           value: 22,
				//           name: 'cooperation',
				//           isShow: true,
				//         },
				//       ],
				//     },
				//   ]
				//   this.areaTwoList = [
				//     {
				//       label: this.$t('N_I_3'),
				//       value: 21,
				//       name: 'haiFans',
				//       isShow: true,
				//     },
				//   ]
				// } else if (this.userInfo.registerFans == 0) {
				//   this.zoneList = [
				//     {
				//       label: this.$t('N_I_1'),
				//       value: 100,
				//       name: 'share',
				//       isShow: true,
				//       children: [
				//         {
				//           label: this.$t('N_I_2'),
				//           value: 7,
				//           name: 'hiFans',
				//           isShow: true,
				//         },
				//       ],
				//     },
				//   ]
				//   this.areaTwoList = [
				//     {
				//       label: this.$t('N_I_2'),
				//       value: 7,
				//       name: 'hiFans',
				//       isShow: true,
				//     },
				//   ]
				// } else {
				api.menuList().then((res) => {
					res.data.forEach((item) => {
						this.zoneList.forEach((ctem) => {
							if (ctem.name == item.menuKey) {
								ctem.isShow = true
							}
							if (ctem.children.length > 0) {
								ctem.children.forEach((stem) => {
									if (stem.name == item.menuKey) {
										stem.isShow = true
										ctem.isShow = true;
									}
								})
							}
						})
					})
					this.$forceUpdate()
					console.log('%c [ this.zoneList ]-810', 'font-size:13px; background:#ca4ea2; color:#ff92e6;',
						this.zoneList)
				})
				// }
			},
			getCatLength() {
				this.$store
					.dispatch('SpecialQuantity', {
						specialArea: this.specialArea,
						pkCountry: this.pkCountry,
					})
					.then((res) => {
						this.carLength = res.data.cont
					})
			},
			showCar() {
				this.$refs.sideBar.getCarList(this.specialArea)
			},
			showShare() {
				this.$refs.sideBar.getShare(this.specialArea)
			},
			openCar() {
				this.$router.push({
					path: '/shoppingCart',
				})
			},
			getOclass(value) {
				this.source = value
				this.getClassIfy()
			},
			// 获取分类一级
			getClassIfy() {
				if (this.specialArea == 21) {
					api
						.optionList({
							parentIds: [0],
						})
						.then((res) => {
							// res.data.unshift({
							//   title: '全部',
							//   tid: '',
							// })
							this.oneList = res.data
							this.oneId = this.oneList[0].pkId
							this.getClassIfyTwo(this.specialArea, this.oneList[0].pkId)
							this.getAllGoods(this.oneList[0].pkId)
						})
				} else {
					api
						.classifyList({
							specialArea: this.specialArea,
							hierarchy: 0,
							pkCountry: this.pkCountry || '',
						})
						.then((res) => {
							res.data.unshift({
								classifyName: this.$t('ENU_GIFT_T_1'),
								pkId: '',
							})
							this.oneList = res.data
							this.oneId = this.oneList[0] ? this.oneList[0].pkId : '';
							this.getClassIfyTwo(this.specialArea, this.oneList[0].pkId);
							this.getAllGoods(this.oneList[0].pkId);
						})
				}
			},
			// 获取分类二级
			getClassIfyTwo(specialArea, pkId) {
				if (this.specialArea == 21) {
					api
						.optionList({
							parentIds: [pkId],
						})
						.then((res) => {
							res.data.unshift({
								title: this.$t('ENU_GIFT_T_1'),
								pkId: '',
							})
							this.twoList = res.data
							let twoIds = res.data.map((item) => {
								return item.pkId
							})
							this.trimSpace(twoIds).then((res) => {
								this.getClassIfyThree(res)
							})

							// threeList
						})
				} else {
					api
						.classifyList({
							specialArea: specialArea,
							pkParent: pkId,
							hierarchy: 1,
							pkCountry: this.pkCountry || '',
						})
						.then((res) => {
							res.data.unshift({
								classifyName: this.$t('ENU_GIFT_T_1'),
								pkId: pkId,
							})
							this.twoList = res.data
							this.twoId = pkId
						})
				}
			},
			// 获取分类三级
			getClassIfyThree(twoIds) {
				api
					.optionList({
						parentIds: twoIds,
					})
					.then((res) => {
						res.data.unshift({
							title: this.$t('ENU_GIFT_T_1'),
							pkId: '',
						})
						this.threeList = res.data
						this.threeId = this.threeList[0].pkId
						// threeList
					})
			},
			trimSpace(array) {
				return new Promise((resolve, reject) => {
					for (var i = 0; i < array.length; i++) {
						//这里为过滤的值
						if (
							array[i] == '' ||
							array[i] == null ||
							typeof array[i] == 'undefined' ||
							array[i] == ''
						) {
							array.splice(i, 1)
							i = i - 1
						}
					}
					resolve(array)
				})
			},
			goDetails(item) {
				let label
				this.zoneList.forEach((stem) => {
					if (stem.value == this.specialArea) {
						label = stem.label
					} else {
						stem.children.forEach((ctem) => {
							if (ctem.value == this.specialArea) {
								label = ctem.label
							}
						})
					}
				})
				if (this.specialArea == '21') {
					this.$router.push({
						path: '/shareDetails',
						query: {
							pkId: item.pkId,
							specialArea: this.specialArea,
							label: label,
							source: this.source,
						},
					})
				} else {
					this.$router.push({
						path: '/goodsDetails',
						query: {
							waresCode: item.waresCode,
							specialArea: this.specialArea,
							label: label,
						},
					})
				}
			},
			getSpecialArea() {
				api.specialArea().then((res) => {
					this.zoneList = res.data
				})
			},
			addToCar(ev, item) {
				// closePopover
				this.$refs.sideBar.closePopover()
				let carList
				if (item.specialArea && item.specialArea != 21) {
					carList = {
						specialArea: item.specialArea,
						number: 1,
						pkId: item.pkId,
						pkCountry: this.pkCountry || '',
						waresCode: item.waresCode,
						productGroup: item.productGroup,
					}
				} else {
					carList = {
						specialArea: 21,
						number: 1,
						pkId: item.pkId, // 海粉商品ID
						source: 0, // 海粉商品渠道ID
						productGroup: item.productGroup,
					}
				}
				if (
					item.isMakerGift == 2 &&
					(item.specialArea == 1 || item.specialArea == 3)
				) {
					this.$refs.selSpaceGoods.getData(carList)
				} else {
					api.addShopping(carList).then((res) => {
						if (res.code == 200) {
							this.$message({
								message: this.$t('w_0036'),
								type: 'success',
							})
							this.getCatLength()
						}
					})
					this.isClick = item.waresCode
					let that = this
					setTimeout(() => {
						that.isClick = ''
					}, 60)
					this.$refs.ball.action(ev.target).then(() => {})
				}
			},
			changeArea(item) {
				if (item.children && item.children.length > 0) {
					let arr = []
					item.children.forEach(ctem => {
						if (ctem.isShow) {
							arr.push(ctem.value)
						}
					})
					// this.specialArea0 = item.children[0].value
					// this.specialArea = item.value
					this.$router.push({
						path: '/shoppingMall',
						query: {
							id: item.value,
							idTh: arr[0]
						},
					})
				} else {
					if (item.value == 30) {
						this.getAuth()
					} else {
						this.$router.push({
							path: '/shoppingMall',
							query: {
								id: item.value
							},
						})
						this.$refs.sideBar.getSpecial(item.value)
					}
				}
				// this.$refs.sideBar.getCarList(this.specialArea)
				// this.getAllGoods()
				// 获取分类
				// this.getClassIfy()
			},
			changeArea2(item) {
				this.$router.push({
					path: '/shoppingMall',
					query: {
						idTh: item.value,
						id: this.specialArea0
					},
				})
			},
			getAllGoods(id) {
				let that = this
				that.loading = true;
				if (this.specialArea == '21') {
					api
						.sharingWares({
							pkCategoryOne: id,
							pageNum: 1,
						})
						.then((res) => {
							this.loading = false;
							this.goodList = res.rows
							this.total = res.total
							this.goodList.forEach((item) => {
								if (item.waresName.length > 26) {
									item.waresName = item.waresName.substring(0, 26) + '...'
								}
							})
						})
				} else {
					api
						.getAllGoods({
							specialArea: this.specialArea,
							pkAreaClassify: id ? id : '',
							pkCountry: this.pkCountry || '',
						})
						.then((res) => {
							this.loading = false;
							this.goodList = res.data
							this.goodList.forEach((item) => {
								if (item.waresName.length > 26) {
									item.waresName = item.waresName.substring(0, 26) + '...'
								}
							})
						})
				}
			},
			getAllGoods1(id) {
				let that = this
				that.loading = true;
				if (this.specialArea == '21') {
					api
						.sharingWares({
							pkCategoryOne: this.oneId,
							pkCategoryTwo: this.twoId,
							pkCategoryThree: this.threeId,
							waresName: this.waresName,
							startPrice: this.startPrice,
							endPrice: this.endPrice,
							pageNum: this.pageNum,
							pageSize: this.pageSize,
						})
						.then((res) => {
							this.loading = false;
							this.total = res.total
							this.goodList = res.rows
							// this.goodList = res.rows
							this.goodList.forEach((item) => {
								if (item.waresName.length > 26) {
									item.waresName = item.waresName.substring(0, 26) + '...'
								}
							})
						})
				} else {
					api
						.getAllGoods({
							specialArea: this.specialArea,
							pkAreaClassify: id ? id : this.oneId,
							pkCountry: this.pkCountry || '',
							waresName: this.waresName,
						})
						.then((res) => {
							this.loading = false;
							this.goodList = res.data
							this.goodList.forEach((item) => {
								if (item.waresName.length > 26) {
									item.waresName = item.waresName.substring(0, 26) + '...'
								}
							})
						})
				}
			},
			// 此处为使用入口
			action(ev) {
				this.$refs.ball.action(ev.target).then(() => {
					this.heartBeat = true
				})
			},
			// 求进入后
			afterEnter() {},
		},
	}
</script>

<style lang="scss" scoped>
	.contain {
		background: rgba(249, 249, 249, 1); // height: 90vh;
		overflow-y: auto;
	}

	.tab {
		position: fixed;
		background: rgba(249, 249, 249, 1);
	}

	.goodArea {
		display: flex;
		align-items: center;
		font-size: 18px;
		font-weight: 600;
		color: #333333;
		padding: 12px 60px 6px 60px;
		justify-content: space-between;

		.goodArea_l {
			display: flex;
			align-items: center;
		}

		.goodArea_r {
			display: flex;
			align-items: center;

			.classIfy {
				// min-width: 113px;
				text-align: center;
				margin: 0 10px;
				cursor: pointer;
				padding: 5px 10px;
				flex-shrink: 0;
			}

			.actOne {
				color: #fff;
				background: #c8161d;
				border-radius: 20px 20px 20px 20px;
				padding: 5px 10px;
				font-weight: 400;
				font-size: 16px;
			}
		}

		.goodArea_i {
			margin-right: 30px;
			cursor: pointer;
		}

		.actArea {
			color: #b42b2a;
		}
	}

	.goodList {
		display: flex;
		align-items: center; // justify-content: space-around;
		padding: 20px 60px;
		flex-wrap: wrap;

		.good_i {
			position: relative;
			cursor: pointer;
			background: #ffffff;
			box-shadow: 0px 2px 10px 0px rgba(172, 172, 172, 0.5);
			border-radius: 10px 10px 10px 10px;
			margin-right: 30px;
			margin-bottom: 30px;
			overflow: hidden;

			.goodBtm {
				width: 278px;
				min-height: 180px;
				padding: 10px 20px 0px 20px;
			}

			.cool {
				position: absolute;
				right: 0;

				img {
					width: 55px;
					height: 60px;
				}
			}

			.motai {
				position: absolute;
				background: rgba(0, 0, 0, 0.3);
				width: 318px;
				height: 318px;
				z-index: 1000;

				div {
					text-align: center;
					margin-top: 30%;
					font-size: 18px;
					font-family: PingFang SC-Regular, PingFang SC;
					font-weight: 400;
					color: #ffffff;
				}
			}
		}

		.good_i:hover {
			transform: scale(1.1);
		}

		.goodImg {
			width: 318px;
			height: 318px;
		}
	}

	.disFlex {
		width: 278px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.disFlex1 {
		width: 278px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between
	}

	.fflex {
		display: flex;
		align-items: center;
		font-size: 14px;
	}

	.ling {
		margin-bottom: 8px;
	}

	.tit1 {
		font-size: 20px;
		font-weight: 600;
		color: #b42b2a;
		margin-right: 10px;
		margin-bottom: 8px;
	}

	.tit2 {
		font-size: 12px;
		font-weight: 400;
		color: #666666;
		margin-bottom: 5px;
	}

	.tit3 {
		font-size: 16px;
		font-weight: 600;
		color: #333333;
		margin-bottom: 12px;
	}

	.tit4 {
		font-size: 14px;
		font-weight: 400;
		color: #999999;
		margin-bottom: 6px;
	}

	.addCarImg {
		// width: 279px;
		// height: 35px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #e2524a;
		font-weight: 500;
		padding: 3px 0;

		img {
			width: 25px;
			height: 25px;
			margin-right: 10px;
		}

		border: 2px solid #e2524a;
		border-radius: 5px;
	}

	.addCarImg1 {
		display: flex;
		justify-content: center;
		align-items: center;
		color: #666;
		font-weight: 500;
		padding: 3px 0;

		img {
			width: 25px;
			height: 25px;
			margin-right: 10px;
		}

		border: 2px solid #666;
		border-radius: 5px;
	}

	.dian {
		width: 22px;
		height: 22px;
		background: #fe0000;
		border-radius: 50%;
	}

	.ccc {
		position: fixed;
		width: 86px;
		height: 1px;
		right: 0;
		text-align: center;
	}

	.oneType {
		display: flex; // align-items: center;
		padding: 0px 60px;
		font-size: 14px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		flex-wrap: wrap; // div {
		//   margin-right: 60px;
		//   cursor: pointer;
		// }
	}

	.clify {
		margin-top: 4px;
	}

	.oneType1 {
		display: flex;
		align-items: center;
		padding: 0px 60px;
		font-size: 14px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		flex-wrap: wrap;
		margin-bottom: 10px;
	}

	.oneType2 {
		flex: 1;
		display: flex;
		flex-wrap: wrap;
		max-height: 85px;
		overflow-y: auto;
	}

	.areaType {
		display: flex;
		align-items: center;
		padding: 0px 60px;
		font-size: 14px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		flex-wrap: wrap;
		margin-bottom: 10px;
	}

	.twoType_a {
		margin: 10px 60px;
		background: rgba(204, 204, 204, 0.2);
		border-radius: 4px 4px 4px 4px;
		border-radius: 10px;
	}

	.twoType {
		display: flex;
		align-items: center;
		min-height: 42px;
		width: 100%;
		overflow-y: hidden; // padding: 5px 0;
		font-size: 12px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		overflow-x: auto; // div {
		//   margin: 10px 26px;
		//   cursor: pointer;
		// }
	}

	.classIfy {
		// min-width: 113px;
		text-align: center;
		margin: 0 10px;
		cursor: pointer;
		padding: 5px 10px;
		flex-shrink: 0;
	}

	.actOne {
		color: #fff;
		background: #c8161d;
		border-radius: 20px 20px 20px 20px;
		padding: 5px 10px;
	}

	.actTwo {
		color: #b42b2a;
	}

	.myCar {
		display: flex;
		width: 168px;
		height: 32px;
		border-radius: 4px;
		border: 2px solid #d81e06;
		font-size: 23px;
		color: #d81e06;
		justify-content: center;
		align-items: center;
		position: relative;
		margin-left: 20px;
		cursor: pointer;

		div {
			font-size: 18px;
			font-weight: 600;
			margin-left: 10px;
		}
	}

	.qzbq {
		background: #d61820;
		border-radius: 2px 2px 2px 2px;
		font-size: 10px;
		font-family: PingFang SC-Semibold, PingFang SC;
		font-weight: 600;
		color: #ffffff;
		padding: 0px 5px;
		margin-right: 5px;
	}

	.md {
		display: flex;
		flex-wrap: wrap;
		margin-top: 5px; // padding-bottom: 10px;

		img {
			height: 22px;
			width: auto;
			margin-right: 10px;
			margin-bottom: 5px;
		}
	}

	.hui {
		color: #666;
	}

	.hui_b {
		background: #666;
	}

	.kuang {
		width: 154px;
		height: 28px;
		border-radius: 4px 4px 4px 4px;
		padding: 0 10px;
		background: #fff;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.dropdown_i {
		display: flex;
		align-items: center;
		color: #666;

		img {
			margin-right: 10px;
			width: 28px;
			height: 19px;
		}
	}

	.search {
		margin: 10px 60px;
		display: flex;
		align-items: center;

		div {
			white-space: nowrap;
			margin-right: 10px;
		}
	}

	.coll {
		display: flex;
		align-items: center;
	}

	.btn {
		background: #c8161d;
		padding: 0 20px;
		color: #fff;
		text-align: center;
		border-radius: 10px;
	}

	.delBtn {
		background: #c8161d;
		color: #fff;
		border-color: #c8161d;
	}

	.delBtn:hover {
		background: #c8161d;
		color: #fff;
		border-color: #c8161d;
	}

	.d-c {
		flex-direction: column;
	}

	.d-c-c {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.expectation-box {
		width: 100%;
	}

	.expectation-img {
		width: 542px;
		height: 326px;
		margin: 20px 0 10px 0;
	}

	.expectation-text {
		font-size: 36px;
		font-weight: bold;
		color: #d61820;
	}
</style>